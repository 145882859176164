<template>
  <b-card
    no-body
  >
    <b-card-body
      :title="title"
    >
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('KorisnickoIme')"
            label-for="korisnickoime"
          >
            <b-form-input
              id="korisnickoime"
              v-model="data.korisnickoime"
              :placeholder="$t('KorisnickoIme')"
            />
            <small
              v-if="formErrors['korisnickoime']"
              class="text-danger"
            >
              {{ $t(formErrors['korisnickoime'][0]) }}
            </small>
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="data.email"
              placeholder="Email"
            />
            <small
              v-if="formErrors['email']"
              class="text-danger"
            >
              {{ $t(formErrors['email'][0]) }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('Lozinka')"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="data.password"
              :placeholder="$t('Lozinka')"
              type="password"
            />
            <small
              v-if="formErrors['password']"
              class="text-danger"
            >
              {{ $t(formErrors['password'][0]) }}
            </small>
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('PonovoLozinka')"
            label-for="password_confirmation"
          >
            <b-form-input
              id="password_confirmation"
              v-model="data.password_confirmation"
              :placeholder="$t('PonovoLozinka')"
              type="password"
            />
            <small
              v-if="formErrors['password_confirmation']"
              class="text-danger"
            >
              {{ $t(formErrors['password_confirmation'][0]) }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('JMBG')"
            label-for="jmbg"
          >
            <b-form-input
              id="jmbg"
              v-model="data.jmbg"
              :placeholder="$t('jmbg')"
            />
            <small
              v-if="formErrors['jmbg']"
              class="text-danger"
            >
              {{ $t(formErrors['jmbg'][0]) }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('Ime')"
            label-for="ime"
          >
            <b-form-input
              id="ime"
              v-model="data.ime"
              :placeholder="$t('Ime')"
            />
            <small
              v-if="formErrors['ime']"
              class="text-danger"
            >
              {{ $t(formErrors['ime'][0]) }}
            </small>
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('Prezime')"
            label-for="prezime"
          >
            <b-form-input
              id="prezime"
              v-model="data.prezime"
              :placeholder="$t('Prezime')"
            />
            <small
              v-if="formErrors['prezime']"
              class="text-danger"
            >
              {{ $t(formErrors['prezime'][0]) }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('Adresa')"
            label-for="adresa"
          >
            <b-form-input
              id="adresa"
              v-model="data.adresa"
              :placeholder="$t('Adresa')"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('Telefon')"
            label-for="telefon"
          >
            <b-form-input
              id="telefon"
              v-model="data.telefon"
              :placeholder="$t('Telefon')"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('Mobilni')"
            label-for="mobilni"
          >
            <b-form-input
              id="mobilni"
              v-model="data.mobilni"
              :placeholder="$t('Mobilni')"
            />
            <small
              v-if="formErrors['mobilni']"
              class="text-danger"
            >
              {{ $t(formErrors['mobilni'][0]) }}
            </small>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumIstekaVozackeDozvole')"
            label-for="datum_isteka_vozacke_dozvole"
          >
            <b-form-datepicker
              id="datum_isteka_vozacke_dozvole"
              v-model="data.datum_isteka_vozacke_dozvole"
              :placeholder="$t('IzaberiDatum')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumIstekaLicneKarte')"
            label-for="datum_isteka_licne_karte"
          >
            <b-form-datepicker
              id="datum_isteka_licne_karte"
              v-model="data.datum_isteka_licne_karte"
              :placeholder="$t('IzaberiDatum')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumIstekaSertifikataZaPotpis')"
            label-for="datum_isteka_sertifikata_za_potpis"
          >
            <b-form-datepicker
              id="datum_isteka_sertifikata_za_potpis"
              v-model="data.datum_isteka_sertifikata_za_potpis"
              :placeholder="$t('IzaberiDatum')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body
      :title="$t('PodaciOInstruktoru')"
    >
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('BrojLicenceInstruktora')"
            label-for="broj_licence_instruktora"
          >
            <b-form-input
              id="broj_licence_instruktora"
              v-model="data.broj_licence_instruktora"
              :placeholder="$t('BrojLicenceInstruktora')"
              type="text"
              :disabled="!data.instruktor || data.instruktor == false"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumLicenceInstruktora')"
            label-for="datum_licence_instruktora"
          >
            <b-form-datepicker
              id="datum_licence_instruktora"
              v-model="data.datum_licence_instruktora"
              :placeholder="$t('IzaberiDatum')"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              :disabled="!data.instruktor || data.instruktor == false"
              no-flip
            />
          </b-form-group>
        </b-col>
      </b-row>

      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('BrojLekarskog')"
            label-for="broj_lekarskog_instruktora"
          >
            <b-form-input
              id="broj_lekarskog_instruktora"
              v-model="data.broj_lekarskog_instruktora"
              :placeholder="$t('BrojLekarskog')"
              :disabled="!data.instruktor || data.instruktor == false"
            />
          </b-form-group>
        </b-col>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('DatumLekarskog')"
            label-for="datum_lekarskog_instruktora"
          >
            <b-form-datepicker
              id="datum_lekarskog_instruktora"
              v-model="data.datum_lekarskog_instruktora"
              :placeholder="$t('IzaberiDatum')"
              :disabled="!data.instruktor || data.instruktor == false"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body
      :title="$t('PodaciOPredavaču')"
    >
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('BrojLicencePredavača')"
            label-for="broj_licence_predavaca"
          >
            <b-form-input
              id="broj_licence_predavaca"
              v-model="data.broj_licence_predavaca"
              :placeholder="$t('BrojLicencePredavača')"
              type="text"
              :disabled="!data.predavac || data.predavac == false"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumLicencePredavača')"
            label-for="datum_licence_predavaca"
          >
            <b-form-datepicker
              id="datum_licence_predavaca"
              v-model="data.datum_licence_predavaca"
              :placeholder="$t('IzaberiDatum')"
              :disabled="!data.predavac || data.predavac == false"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body
      :title="$t('PodaciOIspitivaču')"
    >
      <b-row>
        <b-col
          md="6"
          xl="6"
          class="mb-1"
        >

          <!-- basic -->
          <b-form-group
            :label="$t('BrojLicenceIspitivača')"
            label-for="broj_licence_ispitivaca"
          >
            <b-form-input
              id="broj_licence_ispitivaca"
              v-model="data.broj_licence_ispitivaca"
              :placeholder="$t('BrojLicenceIspitivača')"
              type="text"
              :disabled="!data.ispitivac || data.ispitivac == false"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="6"
          xl="6"
        >
          <b-form-group
            :label="$t('DatumLicenceIspitivača')"
            label-for="datum_licence_ispitivaca"
          >
            <b-form-datepicker
              id="datum_licence_ispitivaca"
              v-model="data.datum_licence_ispitivaca"
              :placeholder="$t('IzaberiDatum')"
              :disabled="!data.ispitivac || data.ispitivac == false"
              :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
              :locale="this.$i18n.locale"
              :hide-header="hideHeader"
              today-button
              reset-button
              close-button
              show-decade-nav
              :label-today-button="$t('Danas')"
              :label-reset-button="$t('Ponisti')"
              :label-close-button="$t('Zatvori')"
              :label-prev-year="$t('PrethodnaGodina')"
              :label-prev-month="$t('PrethodniMesec')"
              :label-next-year="$t('SledecaGodina')"
              :label-next-month="$t('SledeciMesec')"
              :label-current-month="$t('TekuciMesec')"
              :label-prev-decade="$t('MinusDesetGodina')"
              :label-next-decade="$t('PlusDesetGodina')"
              label-help=""
              no-flip
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-card-body>
    <b-card-body>
      <b-row>
        <b-col
          md="4"
          xl="4"
        >
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            @click="DodajZaposlenog"
          >
            {{ $t('Snimi') }}
          </b-button>
          <router-link :to="{name: 'autoskola-zasposleni'}">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="secondary"
            >
              {{ $t('Odustani') }}
            </b-button>
          </router-link>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>

</template>

<script>
import {
  BFormInput, BRow, BCol, BFormGroup, BCard, BCardBody, BFormDatepicker, BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BFormDatepicker,
    BButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      data: {},
      periodi_angazovanja: [
        {
          title: this.$i18n.t('Određeno'),
          value: 'odredjeno',
        },
        {
          title: this.$i18n.t('Neodređeno'),
          value: 'neodredjeno',
        },
      ],
      prikazi_istice_ugovor_na_odredjeno: false,
      hideHeader: true,
      period_angazovanja: '',
      title: '',
      formErrors: [],
      datum_ocitavanja_tahografske_kartice: '',
    }
  },
  watch: {
    data: {
      handler(val) {
        if (val.predavac === true && !this.periodi_angazovanja.some(el => el.value === 'dopunski_rad')) {
          this.periodi_angazovanja.push(
            {
              title: this.$i18n.t('DopunskiRad'),
              value: 'dopunski_rad',
            },
          )
        } else if (!val.predavac || val.predavac === false) {
          this.periodi_angazovanja = this.periodi_angazovanja.filter(item => item.value !== 'dopunski_rad')
        }
        if (val.period_angazovanja && val.period_angazovanja.value === 'odredjeno') {
          this.prikazi_istice_ugovor_na_odredjeno = true
        } else {
          this.prikazi_istice_ugovor_na_odredjeno = false
        }
        if (val.instruktor === true) {
          this.data.procenat_angazovanja = 100
        }
      },
      deep: true,
      title: '',
      formErrors: [],
    },
  },
  mounted() {
    this.pokupiListu()
  },
  methods: {
    pokupiListu() {
      this.title = this.$i18n.t('MojiPodaci')
      this.$http.get('nalog').then(response => {
        this.data = response.data
        this.datum_ocitavanja_tahografske_kartice = response.data.datum_ocitavanja_tahografske_kartice
        if (response.data.period_angazovanja !== null) {
          const selektovaniPeriodAngazovanja = this.periodi_angazovanja.filter(e => e.value === response.data.period_angazovanja)
          const [pa] = selektovaniPeriodAngazovanja
          this.period_angazovanja = pa
        }
      })
    },
    DodajZaposlenog() {
      this.data.period_angazovanja = this.period_angazovanja.value
      this.data.datum_ocitavanja_tahografske_kartice = this.datum_ocitavanja_tahografske_kartice
      this.formErrors = []
      const zaposleniData = this.data
      this.$http.put('nalog', zaposleniData)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$i18n.t('PodaciSuSnimljeni'),
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        })
        .catch(error => {
          if (error.response.status === 422) {
            this.formErrors = error.response.data.errors
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('ProveritePodatke,Neuspesno'),
                icon: 'EditIcon',
                variant: 'danger',
              },
            })
          }
        })
    },
  },
}
</script>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
